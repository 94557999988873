import { styled } from "@mui/material/styles";
import Image from "next/image";

const StepsSection = styled("section")({
  margin: "3rem 0 2rem",

  "@media (min-width: 768px)": {
    margin: "6rem 0 2rem",
  },

  "& header": {
    textAlign: "center",
    marginBottom: 0,

    "@media (min-width: 768px)": {
      marginBottom: "2rem",
    },

    "& h2": {
      fontFamily: "BureauGrotesk, sans-serif",
      fontSize: "36px",
      fontWeight: "400",
      lineHeight: "36px",
      margin: "24px 16px 16px 16px",
      textAlign: "center",

      "@media (min-width: 481px)": {
        fontSize: "64px",
        lineHeight: "64px",
        margin: "24px",
      },
    },
  },

  "& .steps": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "1265px",
    alignItems: "center",

    "@media (min-width: 1024px)": {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "stretch",
    },

    "& .step-card": {
      backgroundColor: "#EBDED5",
      borderRadius: "16px",
      width: "100%",
      maxWidth: "360px",
      margin: "24px 14px" /* override for desktop tp 32 */,
      padding: "20px",

      "& figure": {
        width: "100%",
        maxWidth: "320px",
        height: "293px",
        margin: "0 auto",
        position: "relative",

        "& img": {
          width: "100%",
          objectFit: "contain",
          objectPosition: "50% 50%",
        },
      },

      "& h4": {
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "32px",
        textAlign: "center",
      },

      "& p": {
        fontSize: "1em",
        fontWeight: "400",
        lineHeight: "26px",
        letterSpacing: "0.03em",
        textAlign: "center",
      },
    },
  },
});

const StepData = [
  {
    id: 1,
    img: "/images/share-your-goals.png",
    imgAlt: "Parent and child at computer",
    title: "Share your goals",
    text: "Let us know what type of tutoring you need and when, so we can find you the right matches for your needs.",
  },
  {
    id: 2,
    img: "/images/find-the-perfect-fit.png",
    imgAlt: "Tutor profiles and reviews",
    title: "Find the perfect fit",
    text: "Browse our tutors, get in touch, and book as many free meetings as you need to find the tutor they click with most.",
  },
  {
    id: 3,
    img: "/images/let-the-learning-begin.png",
    imgAlt: "Tutor and student over video call",
    title: "Let the learning begin",
    text: "Once you’ve found the right fit, it’s time to book your lessons and start learning with MyTutor.",
  },
];

const LearningSteps = () => {
  return (
    <StepsSection id="learning-steps" className="learning-steps">
      <header>
        <h2>Start learning in 3 steps</h2>
      </header>
      <div className="steps">
        {StepData.map((step) => (
          <div className="step-card" key={step.id}>
            <figure>
              <Image
                src={step.img}
                alt={step.imgAlt}
                width={320}
                height={293}
                quality={80}
              />
            </figure>
            <h4>{step.title}</h4>
            <p>{step.text}</p>
          </div>
        ))}
      </div>
    </StepsSection>
  );
};

export default LearningSteps;
